<template>
    <section>
      <v-expansion-panels class="" accordion flat v-model="expand">
        <v-expansion-panel>
          <v-expansion-panel-header color="secondary-5"
            class="ma-0 px-7 mat_expansion_panel ">
            <template v-slot:default="{  }">
            <v-container class="ma-0 pa-0 d-flex flex-row align-center roboto f14 fw500 secondary-1--text">
                <v-icon class="mr-3">mdi-video-account</v-icon>
                <section class="d-flex flex-column">
                  <b>Join Live Session</b> 
                  <span class="secondary-2--text f11 font-italic mt-1">Use Google Chrome as the web browser</span>
                </section>
            </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-timeline ">
            <v-container class="roboto secondary-2--text f12 fw500 pl-7" v-if="(course.zoom_meetings.length === 0)">
              No scheduled meetings
            </v-container>
            <v-list v-else>
                <v-list-item dense class="d-flex flex-row align-center pl-7 my-2" v-for="_meeting in course.zoom_meetings" :key="_meeting.id">
                    <section class="d-flex flex-column">
                        <div class="roboto f13 fw500 secondary-1--text">{{_meeting.data.topic}}</div>
                        <span class="roboto f11 secondary-2--text">SCHEDULE: {{ $dateFormat.mmDDyy(_meeting.data.start_date) }} {{ $dateFormat.timeAndPeriod(_meeting.data.start_date +'T' + _meeting.data.start_time + ':00') }}</span>
                    </section>
                    <v-spacer></v-spacer>
                    <!-- <v-btn dense class="primary f10" @click="toMeeting(_meeting)">
                        Join
                    </v-btn> -->
                    <v-btn dense class="primary f12" target="_blank" :href="_meeting.data.join_url">
                      JOIN
                    </v-btn>
                </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </template>
  
  <style>
  .v-expansion-panel-header.mat_expansion_panel {
    align-items: center !important;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  .expansion-timeline .v-expansion-panel-content__wrap {
    padding-bottom: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  </style>
  
  <style scoped>
  p {
    margin-bottom: 5px !important;
  }
  </style>
  
  <script>
  import { mapMutations } from 'vuex';
  export default {
    props: ['course', 'inMeeting'],
    data: () => ({
      expand_material: 0,
    }),
  
    methods: {
      toMeeting(_meeting){
        this.$router.push({ name: 'User Course Zoom Meeting', params: { id: this.$route.params.id , mn: _meeting.id}})
      }
    },

    computed: {
      expand(){
        switch( this.$vuetify.breakpoint.name ) {
            case 'xs': return null
            case 'sm': return null
            case 'md': return null
            case 'lg': return 0
            case 'xl': return 0
        }
      },
    }
    
  }
</script>