<template>
  <section>
      <v-sheet
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="500"
        v-if="notExist"
      >
        <div class="">
          <v-img 
            max-width="300" 
            :src="require('../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Course not Available</h3>
      </v-sheet>
      
      <!-- <MeetingsList id="meetinglist" :course="course" :inMeeting="inMeeting" v-show="showMeetingListSmall" class="mb-5"/> -->
      <v-sheet v-if="!loading && !notExist" min-height="80vh" color="transparent">
        <v-row dense>
          <v-col cols="12" lg="4" class="order-last order-md-first">
            <v-sheet id="right" class="fade custom-border border pa-4 fade">
              <!-- <MeetingsList id="meetinglist" :course="course" :inMeeting="inMeeting" v-show="showMeetingListLarge" class="mt-4"/> -->
              <MeetingsList id="meetinglist" :course="course" :inMeeting="inMeeting"/>
              <!-- <MeetingsList id="meetinglist" v-if="!inMeeting" @joinMeeting="joinMeeting" :course="course" :inMeeting="inMeeting"/> -->
              <!-- <Timeline :course="course" :modules="course.modules"/> -->
              <MaterialTimeline 
                elevation='0'
                :course="course" 
                :modules="course.modules" 
                :current_index="current_index" 
                :course_content="course_content"
                @goTo="goTo"
                class="mt-5"
                :inMeeting="inMeeting"
                id="materialTimeline"/>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="8">
            <v-sheet id="parent" class="custom-border border pa-4 fade">
              <v-sheet v-if="!course.image" color="success" width="100%" height="300" class="border custom-border mb-1"/>
              <v-sheet v-else width="100%" height="300">
                <v-img 
                  alt="rere" 
                  max-height="100%" 
                  max-width="100%" 
                  class="border custom-border mx-auto"
                  :src="course.image.url"
                  v-on:error="require('@/assets/images/course-placeholder-1.png')"/>
              </v-sheet>
              <v-sheet color="#fff" class="py-1 mt-5" id="parent-nav" >
                <v-container flat elevation="0" :class="$vuetify.breakpoint.mobile ? 'd-flex flex-column' : 'd-flex flex-row'">
                  <div >
                    <div class="poppins f18 fw600 success--text mb-3">
                      {{course.title}}
                    </div>
                    <div class="d-flex align-center mb-3">
                      <v-icon class="mr-2" small color="secondary-2">mdi-account-outline</v-icon>
                      <div class="roboto fw500 f13W secondary-2--text text-capitalize" v-if="course.facilitator === null || course.facilitator.length === 0">
                        {{`${course.uploader.first_name} ${course.uploader.last_name} ${course.uploader.suffix ? course.uploader.suffix : ''}`}}
                      </div>
                      <div class="roboto fw500 f13W secondary-2--text text-capitalize" v-else>
                        {{ course.facilitator }}
                      </div>
                    </div>
                    <div class="d-flex align-center mb-3">
                      <v-icon class="mr-2" small color="secondary-2">mdi-clock-outline</v-icon>
                      <div class="roboto fw500 f13W secondary-2--text">
                        {{`${course.training_hours} ${course.training_hours > 1 ? 'hours' : 'hour'}`}}
                      </div>
                    </div>
                  </div>
                  <!-- <v-btn color="success" width="100" class="text-capitalize poppins fw600" link :to="latest_module">View</v-btn> -->
                  <v-spacer />
                  <section class="d-flex flex-column">
                    <v-btn v-if="current_index != null" dense color="primary" class="f12 text-capitalize poppins fw600 mb-3" link :to="{name: 'User Course Content', params: { course_uuid: course.uuid }, query: { uuid: course_content[current_index].uuid, timestamp: Date.now()} }">Continue</v-btn>
                    <v-btn 
                      :disabled="!isCompleted()"
                      color="primary" 
                      outlined
                      dense
                      @click="generate"
                      :loading="certificateLoading">
                      <v-icon small class="mr-1">
                        mdi-certificate-outline
                      </v-icon>
                      Generate Certificate
                    </v-btn>
                  </section>
                </v-container>

                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  slider-color="primary"
                  active-class="white"
                  show-arrows
                >
                  <v-tab
                    v-for="item in items"
                    :key="item"
                    class="roboto text-capitalize f12 fw600"
                    active-class="primary--text"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" style="background-color: transparent">
                  <v-tab-item v-for="item in items" :key="item" style="background-color: transparent">
                    <v-sheet v-if="item === 'ABOUT THIS COURSE'" max-height="350" class="overflow-y-auto pa-3">
                      <div v-if="course.description" class="fade roboto f14 fw500 secondary-1--text my-1" v-html="course.description ? $dompurifier(course.description) : ''"></div>
                      <!-- <div v-if="course.description" class="fade roboto f14 fw500 secondary-1--text my-1" v-html="course.description"></div> -->
                      <div v-if="!course.description" class="fade roboto f14 fw500 secondary-2--text my-1">No course description available.</div>
                    </v-sheet>
                    <v-container v-if="item === 'ANNOUNCEMENTS'">
                      <div v-if="announcement.length === 0" class="fade roboto f14 fw500 secondary-2--text my-1">No announcements available.</div>
                      <v-container v-else >
                        <div v-if="announcement_id">
                          <div v-if="announcement.length > 0" >
                            <v-divider class="mt-3" v-if="!showComments"/>
                            <div class="d-flex align-center">
                              <v-btn icon v-show="showComments" @click="backFromNotif" class="mr-2">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                              <div class="roboto f16 fw600 secondary-1--text my-2">Announcements</div>
                            </div>
                            <v-btn text v-if="showSingleComment" class="f12 secondary-2--text">
                              View all comments ... 
                            </v-btn>
                          </div>
                          <div v-else >
                            <v-divider class="mt-3" v-if="!showComments"/>
                            <div class="d-flex align-center ">
                              <v-btn icon v-show="showComments" @click="showComments=false" class="mr-2">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                              <div class="roboto f16 fw600 secondary-1--text my-2">Announcements</div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="announcement.length > 0" >
                          
                          <div class="d-flex align-center ">
                            <v-btn color="primary" icon v-show="showComments && announcement.length" @click="showComments=false" class="mr-2">
                              <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="roboto f15 fw600 secondary-1--text my-2" v-if="showComments">Comments</div>
                          </div>
                        </div>

                        <div class="announcements fade" v-if="!showComments">
                          <ProfileCard v-for="item in announcement" :key="item.id" 
                            :item="item"
                            action="comment" 
                            icon="mdi-message-outline"
                            :comments="item.comments.length"
                            :allow_comments="item.allow_comments"
                            @comment="comments(item)"
                            :selected_announcement_comment="selected_announcement_comment"
                          />
                        </div>
                        <div class="comments fade" v-else>
                          <ProfileCard v-if="item_comments" :item="item_comments" :selected_announcement_comment="selected_announcement_comment"/>
                          <v-form v-if="announcement.length > 0 && item_comments.allow_comments" @submit.prevent="submit_comment">
                            <v-text-field class="login-custom-field f14 roboto fw500 secondary-1--text mb-5" 
                              dense 
                              filled 
                              flat 
                              placeholder="Comment"
                              hide-details
                              v-model="comment"
                              :loading="comment_load"
                            />
                          </v-form>
                          <v-divider class="my-7" v-if="!item_comments.allow_comments"></v-divider>

                          <div class="text-center font-italic secondary--text" v-if="!item_comments.allow_comments"> Disabled comments</div>

                          <section v-if="item_comments">
                            <ProfileCard :id="`comment-${item.id}`" v-for="item in item_comments.comments"  :key="item.id" 
                              action="reply" 
                              :item="item"
                              icon="mdi-reply-outline"
                              :announcement_id="item_comments.uuid"
                              :allow_comments="item_comments.allow_comments"
                              :replybox = replybox
                              :selected_announcement_comment="selected_announcement_comment"
                              :level = "1"
                            />
                          </section>
                        </div>
                      </v-container>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>

              </v-sheet>
            </v-sheet>
          </v-col>
          
        </v-row>
        <CertificateDialog 
          :dialog="certificateDialog"
          :loading="certificateLoading"
          :url="certificateURL"
          @close="certificateDialog = false"
          />
      </v-sheet>
      <circular v-else-if="loading && !notExist"/>
    </section>
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex';
  import ProfileCard from '../../components/user/announcement/ProfileCard.vue'
  import MaterialTimeline from '../../components/user/course/MaterialTimeline.vue';
  import MeetingsList from '../../components/user/course/MeetingsList.vue';
  import CertificateDialog from '@/components/main/CertificateDialog.vue';

  export default {
    components: {
      ProfileCard,
      MaterialTimeline,
      MeetingsList,
      CertificateDialog
  },
  data: () => ({
    element: '',
    showComments: false,
    showSingleComment: false,
    loading: true,
    comment_load: false,
    selected_announcement: 0,
    comment: null,
    latest_module: null,
    announcement_id: null,
    selected_announcement_comment: null,
    current_index: null, 
    notExist: false,
    inMeeting: false,
    selectedMeeting: null,
    items: ['ABOUT THIS COURSE', 'ANNOUNCEMENTS'],
    tab: null,
    certificateLoading: false,
    certificateDialog: false,
    certificateURL: null
  }),
  computed: {
    ...mapState({
        user: (state) => state.user
    }),
    ...mapState('usr', {
      announcement: (state) => state.object.announcements,
      course: (state) => state.object,
      item_comments(state){
        if(this.$route.params.announcement_id) {
          return state.object.announcements.filter(item  => item.id == this.$route.params.announcement_id)[0]
        }
        return state.object.announcements[this.selected_announcement]
      },
      course_content(state){
        let _course_content = []
          if(state.object.length === 0 && this.$route.params.announcement_id){
            this.loading = false
            this.notExist = true
          } else {
            if(state.object) {
              if(state.object.hasOwnProperty('modules')) {
                state.object.modules.forEach((item, i) => {
                  if(item.data && item.data.length > 0 ){
                    item.data.forEach((module) => {
                      if(item.valid_until === 'anytime' || !this.locked(item.valid_until)) {
                        _course_content.push({expanded: i, ...module})

                        if(module.topic_has_assessments) {
                          module.topic_has_assessments.forEach((assessment) => {
                            _course_content.push({expanded: i, ...assessment, is: 'assessment'})
                          })
                        }
                        if(module.sub_topic_has_assessments) {
                          module.sub_topic_has_assessments.forEach((assessment) => {
                            _course_content.push({expanded: i, ...assessment, is: 'assessment'})
                          })
                        }

                        item.module_evaluations.forEach((_item) => {
                          _course_content.push({expanded: i, ..._item, module_evaluation: true, is: 'module evaluation'})
                        })
                      }

                    })
                  }
                })
                let j = state.object.modules.length
                state.object.course_evaluations.forEach((item) => {
                  _course_content.push({expanded: j, ...item, course_evaluation: true, is: 'course evaluation'})
                  
                })
              }
            }
          }
        return _course_content
      },
      // course_announcements(state) {
      //   state.object.
      // }
    }),
    ...mapState({
      replybox: (state) => state.replybox,
    }),
    showMeetingListLarge(){
      switch( this.$vuetify.breakpoint.name ) {
          case 'xs': return false
          case 'sm': return false
          case 'md': return false
          case 'lg': return true
          case 'xl': return true
      }
    },
    showMeetingListSmall(){
      switch( this.$vuetify.breakpoint.name ) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return true
          case 'lg': return false
          case 'xl': return false
      }
    },
  },
  created () {
    
    // if(!this.inMeeting) {
    //   document.getElementById("zmmtg-root").style.display = "none"
    //   document.getElementById("zmmtg-root").style.zIndex = "-1"
    // }

    this.showCourseAction(this.$route.params.id).then(() => {
      this.loading=false
      if ( this.current_index == null) {
        this.course_content.forEach((item, i) => {
          if(item.is === 'topic' || item.is === 'sub-topic') {
            if(item.student_done_status) {
              this.current_index = i
            }
          } else if(item.is === 'assessment') {
            if(item.assessment_score.score !== null) {
              this.current_index = i
            }
          } else if(item.is === 'module evaluation') {
            if(item.student_module_evaluations_count > 0) {
              this.current_index = i
            }
          } else if(item.is === 'course evaluation') {
            if(item.student_course_evaluations_count > 0) {
              this.current_index = i
            }
          }
        })
        if(this.course_content.length > 0) {
          if ( this.current_index == null) {
            this.current_index = 0
          }
        }
      }
    })
    this.element = document.querySelector('#parent');
    // this.element.addEventListener('scroll', this.onScroll);
    // this.$store.commit('drawerMutation', true)
    if(this.course_content.length === 0 && this.$route.params.announcement_id){
      this.loading = false
      this.notExist = true
    } else if(this.$route.params.announcement_id && this.$route.params.type == 'Announcement'){
      this.announcement_id =`announcement-${this.$route.params.announcement_id}`
      this.showComments = true
      this.tab = 1
    } else if(this.$route.params.announcement_id && this.$route.params.type == 'Reply'){
      this.showComments = true
      this.selected_announcement = this.$route.params.announcement_id
      this.selected_announcement_comment = this.$route.params.announcement_comment_id
      this.tab = 1
      setTimeout(() => {
        this.$vuetify.goTo(`#comment-${this.selected_announcement_comment}`, { offset: 30})
      }, 2000)
      
    } else {
      this.announcement_id = null
    }

  },
  methods: {
    ...mapActions('usr', ['showCourseAction', 'generateCertificateAction', 'showNextContentAction']),

    ...mapMutations('usr', ['courseContentMutation']),
    // onScroll(e) {
    //   const nav = document.querySelector('#parent-nav');
    //   if(e.target.scrollTop >= 290) {
    //     nav.classList.add('fixed');
    //   }
    // },

    isCompleted(){
      let progress = Math.round(((this.course.total_checked_assessments + this.course.total_done_topics + this.course.total_done_sub_topics) / (this.course.assessments_count + this.course.topics_count + this.course.sub_topics_count)) * 100)
      return progress === 100
    },

    locked(item) {

      let today = new Date().toISOString().split("T")[0];

      if(today > item.valid_until) return false
      return true
    },
    
    comments(e) {
      document.querySelector('#parent-nav').scrollTo(290,0);
      this.showComments=true

      this.selected_announcement = this.announcement.findIndex(i=> i.id == e.id)
      this.showCourseAction(this.$route.params.id)
    },

    async submit_comment() {
      this.comment_load = true
      await this.$api.post(`/user/announcement/comments`, {
        comment: this.comment,
        user_id: this.$store.state.user.id,
        parent_id: null,
        commentable_id: this.item_comments.id,
        announcement_id: this.item_comments.uuid
      }).then(res => {
        this.comment = ''
        this.showCourseAction(this.$route.params.id)
        this.comment_load = false
      })
      
    },

    backFromNotif(){
      this.showComments=false
      this.announcement_id = null
      this.$route.params.announcement_id = null
    },

    goTo(e){
      this.showNextContentAction({
        course_uuid: this.$route.params.id, 
        model_uuid: this.course_content[e].uuid, 
        model_type: this.course_content[e].is == 'topic' ? 'Topic' : 'SubTopic',
        save_progress: true,
        course_id: this.course.id
      }).then(()=>{
        this.$router.push({
          name: 'User Course Content',
          params: { course_uuid: this.$route.params.id, selected_index: e }
        })
      })
      
    },

    // joinMeeting(meeting){
    //   console.log(meeting)
    //   document.getElementById("meetinglist").style.display = "none"
    //   document.getElementById("materialTimeline").style.display = "none"
    //   this.selectedMeeting = meeting
    //   this.inMeeting = true
    // },

    generate(){
      this.certificateLoading = true
      this.generateCertificateAction({course_id: this.course.id}).then(res => {
        this.certificateDialog = true
        this.certificateLoading = false
        this.certificateURL = res
      }).catch(res => {
        console.log(res.code)
        this.certificateLoading = false
      })
    }

  },
  watch: {
     $route(to) {
      // this.inMeeting = false
      // document.getElementById("zmmtg-root").style.display = "none"
      // document.getElementById("zmmtg-root").style.zIndex = "-1"
      // document.getElementById("meetinglist").style.display = "block"
      // document.getElementById("materialTimeline").style.display = "block"

      this.loading=true

      if(to.name == 'User Course Details') {
        this.showCourseAction(this.$route.params.id).then(() => {
          this.loading=false
        })
      }
    },

    course_content(val) {
      if ( this.latest_module == null) {
        var first_module = null
        var module_latest = null
        val.forEach((item, i) => {
          if((item.is == 'topic' || item.is == 'sub-topic') && item.score) {
            
            module_latest = {
              name: 'User Assessment',
              params:{
                uuid: item.uuid
              }
            }
          } else if (item.student_done_status){
            module_latest = {
              name: 'User Learning Material',
              params:{
                course_uuid: this.course.uuid, 
                content_uuid: item.uuid, 
                type: item.is,
                save_progress: false,
                course_id: this.course.id
              }
            }
          }
        })

        if (!module_latest) {
          if(val.length > 0){
            if(val[0].is == 'topic' || val[0].is == 'sub-topic') {
              first_module = {
                name: 'User Learning Material',
                params:{
                  course_uuid: this.course.uuid, 
                  content_uuid: val[0].uuid, 
                  type: this.course_content[0].is,
                  save_progress: false,
                  course_id: this.course.id
                }
              }
            } else {
              first_module = {
                name: 'User Assessment',
                params:{
                  uuid: val[0].uuid
                }
              }
            }
            module_latest = first_module
          }
          
        }
        this.latest_module = module_latest
        
      }
    },
  },
}
</script>

<style scoped>
#parent::-webkit-scrollbar {
  display: none;
	/* height: 5px; */
}
#right::-webkit-scrollbar {
  display: none;
	/* height: 5px; */
}
.fixed {
  position: sticky;
  top: -20px;
  z-index: 1;
}

p {
  margin-bottom: 5px !important;
}
</style>