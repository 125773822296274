import { render, staticRenderFns } from "./CertificateDialog.vue?vue&type=template&id=640337c9&scoped=true&"
import script from "./CertificateDialog.vue?vue&type=script&lang=js&"
export * from "./CertificateDialog.vue?vue&type=script&lang=js&"
import style0 from "./CertificateDialog.vue?vue&type=style&index=0&id=640337c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640337c9",
  null
  
)

export default component.exports